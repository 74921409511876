/**
 * Theme Buttons
 * DBS>Interactive
 */

/**
 * 	Button color variable
 *	$grayDarkest: #2d2d2d; —— See _vars.scss
 */
 $buttonColor: $white;


/**
 * Button mixin
 * @param $color1 - foreground color
 * @param $color2 - background color
 */
@mixin button ($color1, $color2) {
	@extend %button-type;
	background-color: $color2;
	border: 1px solid $color2;
    border-radius: 2rem;
	color: $color1;
	display: inline-block;
    font-family: $bodyFont;
	font-weight: 700;
    line-height: 1;
	padding: 1.25rem 5.25rem 1.25rem 1.25rem;
	text-decoration: none;
	transition: all .25s ease;
	-webkit-appearance: nowrap;
	white-space: nowrap;

	// Swap the colors on hover
	&:hover, &:active, &:focus {
		background-color: $color1;
		border-color: $color2;
		color: $color2;
	}
}


/**
 *
 *	CSS Classes (or how to apply the mixin declared above)
 *   	Overview:
 *  		There is a .button class
 *  		There is a modifying .button--ghost class
 *  			.button--ghost builds on .button by being included after the button class and applying a few overrides to the .button styling.
 *
 *  			HTML Example:
 *  			<a class="button button--ghost" href="#">Example Button</a>
 *
 *  	Additional styles can be added on a per project basis. When possible, use the .button--ghost class as an example
 *  	and build off of the .button class styles; avoid creating a separate class and using @include button(), for this
 *  	will cause (potential) unnecessary CSS bloat.
 */

/**
 *  Button Styling
 */
.button,
.freeform-form button.button {
    @extend %default-type;
	@include button ($tealDark, $buttonColor);
    font-weight: bold;
	position: relative;

	&:before,
	&:after {
		content: '';
		height: 2.75rem;
		width: 2.75rem;
		position: absolute;
			top: 50%;
			right: .5rem;
		transform: translate(0, -50%);
		transition: .25s ease-in-out;
	}

	&:before {
		background-image: url('/icons/circle-arrow-dark-teal.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		opacity: 1;
	}

	&:after {
		background-image: url('/icons/circle-arrow-white.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		opacity: 0;
	}

	&:hover,
	&:focus {
		&:before,
		&:after { transform: translate(.25rem, -50%); }

		&:before {opacity: 0;}
		&:after {opacity: 1;}
	}

	&.no-arrow {
		padding-right: 1.25rem;

		&::before,
		&::after {content: none;}
	}
}

/**
 *	Button styling on a light background/teal alternate button
 */
.bg-light .button,
.button.teal {
    @extend %default-type;
	@include button ($buttonColor, $tealDark);
	&::before {background-image: url('/icons/circle-arrow-black.svg');}

	&::after {background-image: url('/icons/circle-arrow-dark-teal.svg');}
}

/**
 *	Inline (Arrow) Button Styling
 */
.arrow-link {
	color: $textColor;
	display: inline-block;
	padding-right: 2.5rem;
	position: relative;
	text-decoration: none;

	&:after {
		background-image: url('/icons/circle-arrow-black.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		content: '';
		display: inline-block;
		height: 1.5rem;
		position: absolute;
			top: 50%;
			right: -0;
		transform: translate(0,-50%);
		transition: transform .25s ease-in-out;
		width: 1.5rem;
	}

	&:hover, &:focus {
		color: $textColor;

		&:after {transform: translate(.5em, -50%);}
	}

	&.back {
		padding-left: 2rem;

		&:after {
			left: 0;
			right: auto;
			transform: translate(0,-50%) rotate(180deg);
		}

		&:hover,
		&:focus {
			&:after {transform: translate(-.5rem,-50%) rotate(180deg);}
		}
	}

	&.long-arrow {
		padding-right: 5rem;
		&::after {
			background-image: url('/icons/long-arrow.svg');
			width: 4rem;
		}
	}
}

.bg-light,
.bg-white {
	.arrow-link {
		color: $grayDarkest;

		&:hover, &:focus {color: $grayDarkest;}
	}
}

.flickity-button {
    padding: 0;
}

.non-button {
	appearance: none;
	background: none;
	border: none;
	color: $textColor;
	padding: 0;
}