.mega-menu {

	&:hover {
		.menu__submenu {
			pointer-events: all;
		}
	}

	// Override
	.menu__submenu {
		left: -15rem;
		pointer-events: none;
		width: auto;
	}

	&__wrapper {
		width: 57rem;
	}

	&__list {
		border-right: 1px solid rgba($white, .2);
		width: 30%;

		&-item {
			border-bottom: 1px solid rgba($white, .2);

			&:last-child {border: none;}

			.mega-menu__list-link {
				display: flex;
					align-items: center;
				font-size: .95rem;
				padding: .5rem 1rem;
				transition: .25s background;

				&:hover,
				&:focus,
				&:active {
					background: $bodyBackground;
				}
			}
		}

		&-image {
			height: 0;
			overflow: hidden;
			padding-bottom: 23%;
			position: relative;
			width: 40%;

			img { @include object-fit(cover, center); }
		}

		&-title {
			padding: 1rem;
			width: 60%;
		}
	}

	&__panel {
		width: 70%;

		&-item {
			opacity: 0;
			padding: $spacing;
			position: absolute;
			visibility: hidden;
			width: 100%;

			&.current-item-previewed {
				opacity: 1;
				position: relative;
				visibility: visible;
			}
		}

		&-image {
			height: 0;
			overflow: hidden;
			padding-bottom: 60%;
			position: relative;
			width: 100%;

			img { @include object-fit(cover, center); }
		}

		&-arrow {
			background: url('/icons/arrow-right-teal.svg') no-repeat right;
				background-size: 1rem;
			padding-right: 1.5rem;
		}

		&-sizes {
			margin-top: .25rem !important;
		}

		&-size:not(:last-child) {
			border-right: 1px solid rgba($white, .2);
			margin-right: 1rem;
			padding-right: 1rem;
		}

		&-size {
			a {
				border-bottom: 2px solid transparent;
				padding: 0;
				transition: border-color .25s ease-in-out;

				&:hover,
				&:focus {
					border-color: $tealDark;
				}
			}
		}
	}

	&__bottom {
        border-top: 1px solid rgba(255,255,255,.2);
        
        a {
            padding: 1rem 2rem;
        }
        
        &>a {
            transition: .25s background;
            
            &:hover {
                background: $grayDarker;
            }
        }
        
        &--right {            
            a {
                background: $tealDark;
                display: flex !important;
                    align-items: center;
                    justify-content: center;
                transition: .25s background, .25s color;
                
                &:first-child {
                    border-right: 1px solid $white;
                    
                    svg {
                        height: 15px;
                        margin-right: #{$spacing / 2};
                        width: 26px;
                        
                        path,
                        rect {
                            transition: .25s stroke;
                        }
                    }
                    
                    &:hover,
                    &:focus,
                    &:active {
                        svg {
                            path,
                            rect {
                                stroke: $grayDarker;
                            }
                        }
                    }
                }
                
                &:last-child {
                    svg {
                        height: 20px;
                        margin-right: #{$spacing / 2};
                        width: 22px;
                        
                        path {
                            transition: .25s fill;
                        }
                    }
                    
                    &:hover,
                    &:focus,
                    &:active {
                        svg {
                            path {
                                fill: $grayDarker;
                            }
                        }
                    }
                }
    
                &:hover,
                &:focus,
                &:active {
                    background: $teal;
                    color: $grayDarker;
                }
            }
        }
	}
}