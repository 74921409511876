/**
 * DBS>Interactive
 * General Theme Type classes
 * These classes are generic repeatable patterns of type styles that can be
 * extended by other classes to maintain consistency throughout the theme.
 *
 * Recommended usage:
 * 		@extend %button-type;
 */


/**
 * Default Typography
 */
html {
	@extend %default-type;
	font-size: $bodyFontSize;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
	scroll-behavior: smooth;
}

body {
	@extend %default-type;
	font-size: $bodyFontSize;
	font-weight: 400;
	line-height: $bodyLineHeight;
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5 {
	@extend %header-type;
	margin-top: 0;
	margin-bottom: $vSpacing * 1;

	a { text-decoration: none; }
}

nav {
	@extend %nav-type;
}

.h1, h1 { @include font-scale( 5, $headingFontSize); }
.h2, h2 { @include font-scale( 4, $headingFontSize); }
.h3, h3 { @include font-scale( 3, $headingFontSize); }
.h4, h4 { @include font-scale( 2, $headingFontSize); }
.h5, h5 { @include font-scale( 1, $headingFontSize); }
.h6, h6 { @include font-scale( 1, $headingFontSize); }


@include media($screen-sm) {
	.h1, h1 { @include font-scale( 6, $headingFontSize); }
	.h2, h2 { @include font-scale( 5, $headingFontSize); }
	.h3, h3 { @include font-scale( 4, $headingFontSize); }
	.h4, h4 { @include font-scale( 3, $headingFontSize); }
	.h5, h5 { @include font-scale( 2, $headingFontSize); }
	.h6, h6 { @include font-scale( 1, $headingFontSize); }
}


.eyebrow {
	font-family: $font-poppins;
	font-size: 1.125rem !important;
    font-weight: 400;
    letter-spacing: 3.235px;
    line-height: 2;
	margin-bottom: 2rem;
	position: relative;
	text-transform: uppercase;

	&::before {
		border-top: 2px solid $teal;
		content: "";
		display: block;
		width: 4rem;
		position: absolute;
		bottom: -1rem;
		left: 0;
	}
}

.underline-heading {
	padding-bottom: $spacing;
	position: relative;

	&::after {
		border-top: 3px solid $tealDark;
		content: '';
		position: absolute;
			bottom: 0;
			left: 0;
		width: 6.25rem;
	}
}

.centered .eyebrow:before,
.text-center .eyebrow:before,
.text-center.eyebrow:before,
.eyebrow[style*="text-align:center"]::before {
    left: 50%;
	transform: translateX(-50%);
}

.text-right .eyebrow:before,
.text-right.eyebrow:before{
	left: auto;
	right: 0;
}

a {
	color: $linkColor;
	font-weight: 600;
	text-decoration: underline;
	transition: color ease-in-out 0.25s;

	&:hover, &:focus, &:active { text-decoration: none;}
}

.bg-dark a:not(.button) {
	color: $white;

	&:hover, &:focus, &:active { color: darken($white, 10%); }
}


p {
	margin-top: 0;
	margin-bottom: $spacing;
}

.dropcap {
	color: $primaryColor;
	float: left;
	font-size: 6.25em;
	font-weight: 400;
	line-height: 90px;
	margin-bottom: -.031em;
	margin-top: .03em;
	padding-right: .5rem;
}

.font_small,
small { font-size: 0.9em; }

.intro-text,
.bigger-text {
	font-size: 1.125rem;
}

.font-size-xs, .fs-xs { font-size: .875rem; }
.font-size-sm, .fs-sm { font-size: .9375rem; }
.font-size-md, .fs-md { font-size: 1rem; }
.font-size-lg, .fs-lg { font-size: 1.125rem; }
.font-size-xl, .fs-xl { font-size: 1.25rem; }
.font-size-xxl, .fs-xxl { font-size: 1.375rem; }
.font-size-xxxl, .fs-xxxl { font-size: 1.5rem; }

b, strong {font-weight: bold;}

.poppins {font-family: $font-poppins;}
.noto-serif {font-family: $font-noto-serif;}

// Color Classes
.white {color: $white !important;}
.black {color: $black !important;}
.teal:not(.button) {color: $teal !important;}
.dark-teal {color: $tealDark !important;}

// Type Style Classes
.caps {text-transform: uppercase;}
.underline {text-decoration: underline;}

// Alignment Classes
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}

// Font Weight Classes
.normal {font-weight: 400;}
.semi-bold {font-weight: 600;}
.bold {font-weight: 700;}
.extra-bold {font-weight: 900;}
